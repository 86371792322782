import React, {useEffect, useState} from 'react';
import growth from '../../../utils/images/masters/growth.png';
import weight from '../../../utils/images/masters/weight.png';
import chest from '../../../utils/images/masters/chest.png';
import hair from '../../../utils/images/masters/hair.png';
import Header from "../../../components/Header/Header";
import HeaderMobileMenu from "../../../components/Header/HeaderMobileMenu";
import Footer from "../../../components/Footer/Footer";
import {Button, Image, Modal, Input} from "antd";
import { useLocation, Link } from 'react-router-dom'
import MobileBar from "../../../components/MobileBar/MobileBar";
import logo from "../../../utils/images/homePage/logo.png";
import InputMask from 'react-input-mask';
import closeImg from "../../../utils/images/header/close.png";
import popUpBg from "../../../utils/images/popup.png";
import popUpHorizontal from "../../../utils/images/popup.png";
import popUpMobile from "../../../utils/images/popupMobile.png";
import {Helmet} from "react-helmet";

export default function MasterId() {
  const location = useLocation()
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [visible, setVisible] = useState(false);
  const master = location.state.master;

  const phoneNumberMask = '+7 999 999 99 99';

  const onValueChanged = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  const modalClose = () => {
    setIsModal(false);
  };

  const modalOpen = () => {
    setIsModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onNameChange = (event) => {
    setName(event.target.value);
  }

  const isMobile = window.innerWidth < 1015;

  const isHorizontal = window.innerHeight < window.innerWidth;

  return (
    <div>
      <Helmet>
        <title>{master.name} | Мастер мужского клуба САФАРИ</title>
        <meta name="description" content={`Мастер мужского клуба САФАРИ - ${master.name}: фотографии,параметры. Релакс для настоящих мужчин от рук обворожительных девушек. Большой выбор программ, акции и скидки. Работаем круглосуточно.`}/>
      </Helmet>
      {
        isMobile ? (
          <div style={{background: 'black'}}>
            {isOpenMenu ? (
              <HeaderMobileMenu buttonClick={onValueChanged}/>
            ) : (
              <>
                <Header buttonClick={onValueChanged} isOpenMenu={isOpenMenu}/>
                <div className="masters-page">
                  <h1 className="master-id-title">
                    {master.name}
                  </h1>
                  <span className="master-id-info">
                <img className="master-id-desc-icon" src={hair} alt={'hair'}/><span style={{marginRight: '12px'}} >{master.hair}</span>
                <img className="master-id-desc-icon" src={growth} alt={'chest'} /><span style={{marginRight: '12px'}}>{master.growth}</span>
                <img className="master-id-desc-icon" src={weight} alt={'weight'}/><span style={{marginRight: '12px'}}>{master.weight}</span>
                <img className="master-id-desc-icon" src={chest} alt={'chest'}/><span style={{marginRight: '12px'}}>{master.chest}</span>
              </span>
                  <div className="master-images">
                    <Image.PreviewGroup>
                      <Image src={master.image[0]} alt='img1' width={'100%'} height={454} style={{maxWidth: 388}}  preview={{src: master.fullImage[0]}}/>
                      <div className="master-images-row" style={{maxWidth: 388, margin: '20px auto 40px'}}>
                        {master.image.map((item, index) => (
                          index !== 0 && (
                            <div key={index} style={{ width: 'calc(33% - 11px)', marginRight: index + 1 !== master.image.length ? '16px' : '0px'}}>
                              <Image
                                src={item}
                                alt={'image' + index}
                                height={123}
                                width={'100%'}
                                style={{maxWidth: 116}}
                                preview={{src: master.fullImage[index]}}
                                // onClick={() => setVisible(true)}
                              />
                            </div>
                          )
                        ))}
                        <div style={{ display: 'none' }}>
                          <Image.PreviewGroup current={3} preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>
                            {master.fullImage.map((item, index) => (
                              <Image
                                src={item}
                                alt={'image' + index}
                                height={123}
                                width={'100%'}
                                preview={{ visible: false }}
                                onClick={() => setVisible(true)}
                              />
                            ))}
                          </Image.PreviewGroup>
                        </div>
                      </div>
                    </Image.PreviewGroup>
                  </div>
                  <div style={{maxWidth: '500px', margin: '0 auto'}}>
                    <Button
                      className="pink-gradient-button text-white font-montserrat text-12"
                      style={{width: '100%', maxWidth: '388px'}}
                      onClick={modalOpen}
                    >
                      ВЫБРАТЬ ЕЕ
                    </Button>
                    <Link to='/masters'>
                      <Button className="button-all-masters text-white font-montserrat text-12" style={{ maxWidth: '388px'}}>СМОТРЕТЬ ВСЕХ</Button>
                    </Link>
                  </div>
                </div>
                {isModal && (
                  <Modal
                    maskClosable={true}
                    open={isModal}
                    onCancel={modalClose}
                    footer={null}
                  >
                    <div style={{backgroundImage: `url(${isHorizontal ? popUpHorizontal : popUpMobile})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%',  paddingBottom: '40px'}}>
                      <div style={{textAlign: "right"}}>
                        <img src={closeImg} alt={'close'} width={24} height={24} onClick={modalClose}/>
                      </div>
                      <div className="logo-img-pop-up">
                        <img src={logo} alt={'logo'} width={155} height={69}/>
                      </div>
                      <div style={{marginTop: '12px', textAlign: 'center'}}>
                        <h2 className="pop-up-title">Мы ждем тебя!</h2>
                        <p className="pop-up-description" >
                          Оставь контакты и мы свяжемся с тобой<br/>
                          в ближайшее время  для уточнения всех деталей
                        </p>
                        <Input bordered={true} className='input-pop-up' placeholder={'Имя'} onChange={onNameChange} style={{maxWidth: isMobile ? '303px' : 'unset'}}/>
                        <InputMask
                          style={{padding: '2px 10px', width: 'calc(100% - 22px)', maxWidth: isMobile ? '283px' : 'unset'}}
                          className='input-pop-up'
                          mask={phoneNumberMask}
                          maskChar={null}
                          onChange={(e)=> setPhone(e.target.value)}
                          placeholder={'+7 ___ ___ __ __'}
                        />
                        <a href={`https://wa.me/+79231136666?text=Здравствуйте%2C+Имя:+${name}+телефон:+${phone}`} target="_blank" style={{textDecoration: 'none'}}>
                          <Button
                            className="pink-gradient-button text-white font-montserrat text-12"
                            style={{width: '100%', maxWidth: isMobile ? '303px' : 'unset'}}
                            onClick={modalClose}
                          >
                            Отправить
                          </Button>
                        </a>
                      </div>
                    </div>
                  </Modal>
                )}
                <Footer/>
                <MobileBar/>
              </>
            )}
          </div>
        ) : (
          <>
            <Header/>
            <div style={{width: '78vw', margin: '70px auto'}}>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <h1 className="master-id-title">
                  {master.name + ', ' + master.age}
                </h1>
                <Button className="pink-gradient-button text-white font-montserrat text-12" onClick={modalOpen}>ВЫБРАТЬ ЕЕ</Button>
              </div>
              <span className="master-id-info">
            <img className="master-id-desc-icon" src={hair} alt={'hair'}/><span style={{marginRight: '12px'}} >{master.hair}</span>
            <img className="master-id-desc-icon" src={growth} alt={'chest'} /><span style={{marginRight: '12px'}}>{master.growth}</span>
            <img className="master-id-desc-icon" src={weight} alt={'weight'}/><span style={{marginRight: '12px'}}>{master.weight}</span>
            <img className="master-id-desc-icon" src={chest} alt={'chest'}/><span style={{marginRight: '12px'}}>{master.chest}</span>
          </span>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <Image.PreviewGroup>
                  <div style={{ marginRight: '40px'}}>
                    <Image src={master.webImage[0]} width={488} height={895} preview={{src: master.fullImage[0]}}/>
                  </div>
                  <div>
                    <Image src={master.webImage[1]} width={621} height={617} preview={{src: master.fullImage[1]}}/>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '37px'}}>
                      {master.webImage && master.webImage[2] ? (
                        <Image src={master.webImage[2]} width={289} height={241} style={{marginRight: '42px'}} preview={{src: master.fullImage[2]}}/>
                      ) : (
                        <div style={{width: '289px', height: '241px'}}></div>
                      )}
                      {master.webImage && master.webImage[3] ? (
                        <Image src={master.webImage[3]} width={289} height={241} preview={{src: master.fullImage[3]}}/>
                      ) : (
                        <div style={{width: '289px', height: '241px'}}></div>
                      )}
                    </div>
                  </div>
                </Image.PreviewGroup>
              </div>
              <Link to={'/masters'} style={{textDecoration: 'none'}}>
                <div style={{display: 'flex', justifyContent: 'start', marginTop: '66px'}}>
                  <div className="button-choose" ><span style={{margin: "auto"}}>Смотреть <br/>всех</span></div>
                </div>
              </Link>
            </div>
            {isModal && (
              <Modal
                maskClosable={true}
                open={isModal}
                onCancel={modalClose}
                footer={null}
                width={'620px'}
              >
                <div style={{backgroundImage: `url(${popUpBg})`, backgroundSize: '100% 100%', backgroundPosition: 'bottom right', padding: '24px 24px 40px'}}>
                  <div style={{textAlign: "right", marginTop: 10}}>
                    <img src={closeImg} alt={'close'} width={24} height={24} onClick={modalClose}/>
                  </div>
                  <div style={{width: 340, margin: '0 auto'}}>
                    <div className="logo-img-pop-up">
                      <img src={logo} alt={'logo'} width={155} height={69}/>
                    </div>
                    <div style={{marginTop: '12px', textAlign: 'center'}}>
                      <h2 className="pop-up-title">Мы ждем тебя!</h2>
                      <p className="pop-up-description" >
                        Оставь контакты и мы свяжемся с тобой<br/>
                        в ближайшее время  для уточнения всех деталей
                      </p>
                      <Input bordered={true} className='input-pop-up' placeholder={'Имя'} onChange={onNameChange}/>
                      <InputMask
                        style={{padding: '2px 10px', width: 'calc(100% - 22px)'}}
                        className='input-pop-up'
                        mask={phoneNumberMask}
                        maskChar={null}
                        onChange={(e)=> setPhone(e.target.value)}
                        placeholder={'+7 ___ ___ __ __'}
                      />
                      <a href={`https://wa.me/+79231136666?text=Здравствуйте%2C+Имя:+${name}+телефон:+${phone}`} target="_blank" style={{textDecoration: 'none'}}>
                        <Button
                          className="pink-gradient-button text-white font-montserrat text-12"
                          style={{width: '100%'}}
                          onClick={modalClose}
                        >
                          Отправить
                        </Button>
                      </a>
                    </div>
                  </div>
                  <div>
                  </div>
                </div>
              </Modal>
            )}
            <Footer/>
          </>
        )
      }
    </div>

  );
}