import one from '../../../../utils/images/stocks/stock1.png';
import two from '../../../../utils/images/stocks/stock4.png';
import three from '../../../../utils/images/stocks/first-time.png';

export const stocks  = [
  {
    image: one,
    title: 'День рождения? \n У нас есть подарок!'
  },
  {
    image: two,
    title: 'Скидка за подписку',
    },
  {
    image: three,
    title: 'Первый раз +20 минут',
  },
];

