import one from '../../utils/images/stocks/stock1.png';
import two from '../../utils/images/stocks/stock2.png';
import three from '../../utils/images/stocks/stock3.png';
import four from '../../utils/images/stocks/stock4.png';
import five from '../../utils/images/stocks/stock5.png';

export const stocks  = [
  {
    image: one,
    title: 'День рождения? \n У нас есть подарок!',
    description: '4 дня до и 4 дня после дня рождения <b>скидка\n именнинику 15%</b>',
    id: 1
  },
  {
    image: two,
    title: '2+1',
    description: 'Для любителей дополнительных услуг, мы сделали акции три по цене двух. Заказывайте два дополнения,  а третье\nнаша красавица исполнит <b>бесплатно!</b>',
    id: 2
  },
  {
    image: three,
    title: 'Жаркий отдых',
    description: 'Приходи в компании от 3-х человек и получи <b>скидку 15%</b>',
    id: 3
  },
  {
    image: four,
    title: 'Скидка за подписку',
    description: '<b>Дарим 10% скидку</b> на все программы  подписчикам нашего телеграмм-канала',
    id: 4
  },
  {
    image: five,
    title: 'Жаркие будни',
    description: '<b>Скидка 20%</b> на все программы \nс понедельника по четверг \nс 7:00 до 20:00  и в пятницу \nс 7:00 до 17:00',
    id: 5
  },
];

