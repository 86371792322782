import React, {useRef, useState} from 'react';
import './interiorHomePage.css';
import { Carousel, Image } from "antd";
import one from '../../../../utils/images/interior/1.png';
import two from '../../../../utils/images/interior/2.png';
import three from '../../../../utils/images/interior/3.png';
import four from '../../../../utils/images/interior/4.png';
import five from '../../../../utils/images/interior/5.png';
import leftArrow from '../../../../utils/images/interior/left-arrow.svg';
import rightArrow from '../../../../utils/images/interior/right-arrow.svg';
import webOne from '../../../../utils/images/interior/main-web1.png';
import webTwo from '../../../../utils/images/interior/main-web2.png';
import webThree from '../../../../utils/images/interior/main-web3.png';
import webFour from '../../../../utils/images/interior/main-web4.png';

export default function InteriorHomePage() {
  const intRef = useRef();
  const bottomRef = useRef();

  const slideList = [one, two, three, four, five];
  const sliderBottomList = [two, three, four, five, one];

  const [currentSlide, setCurrentSlide] = useState(0);

  const onChange = (current) => {
    setCurrentSlide(current);
  };

  const onLeftArrowClick = () => {
    intRef.current.prev();
    bottomRef.current.prev();
  }

  const onRightArrowClick = () => {
    intRef.current.next();
    bottomRef.current.next();
  }

  const onSwipe = (current) => {
    console.log(current);
    if (current === 'right') {
      intRef.current.prev();
      bottomRef.current.prev();
    } else {
      intRef.current.next();
      bottomRef.current.next();
    }
  }

  const isMobile = window.innerWidth < 1015;

  return (
    isMobile ? (
     <div className="interior-main-page">
      <h3 className="interior-main-title">
        ИНТЕРЬЕР
      </h3>
      <div className="interior-block">
        <Carousel
          ref={ref => {
            intRef.current = ref
          }}
          dots={false}
          afterChange={onChange}
          swipeToSlide={false}
          draggable={false}
          onSwipe={onSwipe}
        >
          {slideList.map((item, index) => (
            <div key={index} style={{width: ''}}>
              <img
                style={{margin: "0 auto", width: '100%', height: '60%'}}
                src={item}
                alt={`slide + ${index}`}
              />
            </div>
          ))}
        </Carousel>
        <div className="left-arrow" onClick={onLeftArrowClick}>
          <img className="left-arrow-icon" src={leftArrow} alt="left"/>
        </div>
        <div className="right-arrow" onClick={onRightArrowClick}>
          <img className="right-arrow-icon" src={rightArrow} alt="right"/>
        </div>
        <Carousel
          style={{marginTop: '12px'}}
          ref={ref => {
            bottomRef.current = ref
          }}
          dots={false}
          afterChange={onChange}
          slidesToShow={4}
          onSwipe={onSwipe}
        >
          {sliderBottomList.map((item, index) => (
            <div key={index} style={{padding: '0 7px'}}>
              <img
                style={{ width: 'calc(100% - 5px)',  height: '73%'}}
                src={item}
                alt={`slide + ${index}`}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
    ) : (
      <div className="interior-main-page">
        <h3 className="interior-main-title">
          ИНТЕРЬЕР
        </h3>
        <p className="interior-desc">
          Раскошный интерьер, прекрасная музыка и компания сексуальных, <br/>
          обворожительных девушек... <br/>
          Разве не о таком отдыхе мечтает каждый мужчина?
        </p>
        <div className="interior-block">
          <Image.PreviewGroup>
           <div style={{marginRight: '60px'}}>
             <Image className="inter-img" src={webOne} preview={{src: one}} width={560} height={563}/>
           </div>
          <div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '38px'}}>
              <Image className="inter-img" src={webTwo} preview={{src: two}} width={254} height={250}/>
              <Image className="inter-img" src={webThree} preview={{src: five}} width={260} height={250}/>
            </div>
            <Image className="inter-img" src={webFour} preview={{src: three}} width={560} height={275}/>
          </div>
          </Image.PreviewGroup>
        </div>
      </div>
    )
  );
}