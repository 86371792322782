import React, {FC} from 'react';
import './mobileBar.css';
import present from "../../utils/images/mobileBar/present.svg";
import book from "../../utils/images/mobileBar/open-book.svg";
import heart from "../../utils/images/mobileBar/heart.svg";
import {Link} from "react-router-dom";

export const MobileBar: FC = () => {

  return (
    <div className="mobile-bar">
      <Link className="tab-bar nav-text" to="/masters" >
        <div >
          <div style={{width: '100%'}}>
            <img src={heart} alt="heart"/>
          </div>
          <span style={{width: '100%'}}>Мастера</span>
        </div>
      </Link>
      <Link className="tab-bar nav-text" to="/programms">
        <div>
        <div style={{width: '100%'}}>
          <img src={book} alt="book"/>
        </div>
        <span style={{width: '100%'}}>Программы</span>
      </div>
      </Link>
      <Link className="tab-bar nav-text" to="/stocks">
       <div >
        <div style={{width: '100%'}}>
          <img src={present} alt="present"/>
        </div>
        <span style={{width: '100%'}}>Акции</span>
      </div>
      </Link>
    </div>
  );
}

export default MobileBar;