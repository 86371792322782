import React from 'react';
import './ourBenefits.css';
import { benefits } from './mock';
import benefitsBg from '../../../../utils/images/benefits/benefints-web.png';

export default function OurBenefits() {

  const isMobile = window.innerWidth < 1015;

  return (
    <div className="benefits-page">
      {isMobile ? (
        <>
          <h3 className="benefits-title">
            НАШИ<br/>ПРЕИМУЩЕСТВА
          </h3>
          <div>
            {benefits.map((item,index) => (
              <div key={index} className="benefits-block">
                <div style={{width: '42px', marginRight: '16px'}}>
                  <img src={item.image} alt={'benefits' + index}/>
                </div>
                <div className="benefits-text">
                  <span><b>{item.title}</b></span>
                  <br/>
                  <span>{item.description}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div>
            <img src={benefitsBg} alt={'benefits-img'} width={640} height={890}/>
          </div>
          <div style={{width: '560px', paddingTop: '127px'}}>
            <h3 className="benefits-title">
              НАШИ<br/>ПРЕИМУЩЕСТВА
            </h3>
            <div>
              {benefits.map((item,index) => (
                <div key={index} className="benefits-block">
                  <div style={{width: '42px', marginRight: '16px'}}>
                    <img src={item.image} alt={'benefits' + index} width={60} height={60}/>
                  </div>
                  <div className="benefits-text">
                    <span><b>{item.title}</b></span>
                    <br/>
                    <span>{item.description}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}