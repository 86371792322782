import React, {useState} from 'react';
import './mainStocks.css';
import { stocks } from './mock';
import { stocks as webStocks} from '../../../Stocks/mock';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';
import arrowLeft from '../../../../utils/images/arrowLeftCircle.png';
import arrowRight from '../../../../utils/images/arrowRightCircle.png';
import stockMobile from '../../../../utils/images/stocks/stock4-mobile.png';

export default function MainStocks() {

  const isMobile = window.innerWidth < 1015;

  const [currentStock, setCurrentStock] = useState(webStocks[0]);

  const onRightArrowClick = () => {
    if (currentStock.id === webStocks.length) {
      setCurrentStock(webStocks[0]);
    } else {
      setCurrentStock(webStocks[currentStock.id]);
    }
  }

  const onLeftArrowClick = () => {
    if (currentStock.id === 1) {
      setCurrentStock(webStocks[webStocks.length - 1]);
    } else {
      setCurrentStock(webStocks[currentStock.id - 2]);
    }
  }

  const widthMobile = window.innerWidth > 600

  return (
    <div className="main-stocks-page">
      <h3 className="stock-title">
        АКЦИИ
      </h3>
      {isMobile ? (
        <>
          <div className="masters-main-page" style={{maxWidth: widthMobile ? '700px' : '450px', margin: '0 auto'}}>
            {stocks.map((item, index) => (
              <div className="stock-card">
                <img src={index === 1 ? stockMobile : item.image} height={widthMobile ? 230 : 170} width={widthMobile ? '180px' : '100%'} alt={'img' + index}/>
                <div className="card-info">
                  <span className="stock-description">{item.title}</span>
                </div>
              </div>
            ))}
          </div>
          <Link className="nav-text" to='/stocks'>
            <div className="button-more-stocks" style={{maxWidth: widthMobile ? '700px' : '450px', margin: '20px auto 0'}}> Узнать подробнее</div>
          </Link>
        </>
      ) : (
        <>
          <div style={{display: 'flex', justifyContent: 'center', marginTop: '60px'}}>
            <div style={{marginRight: '40px'}}>
              <p className="stock-card-title">
                {currentStock.title}
              </p>
              <p className="stock-card-description">
                {parse(currentStock.description)}
              </p>
              {currentStock.id === 2 && (
                <p className="stock-card-description">
                  *не распространяется на дополнительные программы:<br/>Неожиданный поцелуй, Купить белье мастера, <br/>Розовый веер (полноценное лесби-шоу), Выкупить
                  номер телефона мастера
                </p>
              )}
              <a href={`https://wa.me/+79231136666?`} target="_blank" style={{textDecoration: 'none'}}>
                <div className="button-more-stocks"> ЗАБРОНИРОВАТЬ</div>
              </a>
            </div>
            <div>
              <img src={currentStock.image} alt={'current-stock'} width={540} height={660}/>
            </div>
          </div>
          <img className="arrow-left-circle" src={arrowLeft} alt={'arrowLeft'} width={45} height={45} onClick={onLeftArrowClick}/>
          <img className="arrow-right-circle" src={arrowRight} alt={'arrowRight'} width={45} height={45} onClick={onRightArrowClick}/>
        </>
      )}
    </div>
  );
}