import one from '../../../../utils/images/benefits/1.svg';
import two from '../../../../utils/images/benefits/2.svg';
import three from '../../../../utils/images/benefits/3.svg';
import four from '../../../../utils/images/benefits/4.svg';
import five from '../../../../utils/images/benefits/5.svg';

export const benefits  = [
  {
    image: one,
    title: 'Подарки',
    description: 'Клиенты нашего премиум салона гарантированно получают приятные бонусы и подарки'
  },
  {
    image: two,
    title: 'Анонимность',
    description: 'Обеспечиваем полную анонимность посещения салона. Ваша анонимность = наша репутация'
  },
  {
    image: three,
    title: 'Время',
    description:'Каждая программа может длится больше, \nно не меньше!'
  },
  {
    image: four,
    title: 'Безопасность',
    description: 'Каждая массажистка в строгом порядке имеет\n медицинскую книжку и проходит обследования регулярно'
  },
  {
    image: five,
    title: 'Гигиена',
    description:'Наши салоны отвечают самым высоким \nтребованиям гигиены. Каждому гостю \n предоставляется одноразовый набор'
  },
];

