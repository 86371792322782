import React from 'react';
import './ourMasters.css';
import { masters } from '../../../Masters/mock';
import growth from '../../../../utils/images/masters/growth.svg';
import weight from '../../../../utils/images/masters/weight.svg';
import chest from '../../../../utils/images/masters/chest.svg';
import { Link } from 'react-router-dom';
import {Button} from "antd";

export default function OurMasters() {
  const mastersMock = [masters[0], masters[1]];
  const mastersMockFull = [masters[0], masters[1], masters[2]];

  const widthMobile = window.innerWidth > 600;
  const isMobile = window.innerWidth < 1015;

  return (
    isMobile ? (
      <div className="our-masters-page" >
      <h3 className="master-title-main-page" >
        НАШИ МАСТЕРА
      </h3>
      <div className="masters-main-page" style={{maxWidth: widthMobile ? '601px' : '388px', margin: '0 auto'}}>
        {!widthMobile ?
          mastersMock.map((item, index) => (
            <div className="master-card">
              <img src={item.mainImage} height={230} width={'100%'} alt={'img' + index} style={{maxWidth: '180px'}}/>
              <div className="card-info">
                <span className="master-description">{item.name + ', ' + item.age}</span>
                <span className="master-description">
                <img className="desc-icon" src={growth} alt={'growth' + index}/>{item.growth}
                  <img className="desc-icon" src={weight} alt={'weight' + index}/>{item.weight}
                  <img className="desc-icon" src={chest} alt={'chest' + index}/>{item.chest}
              </span>
              </div>
            </div>
          )) :
          mastersMockFull.map((item, index) => (
            <div className="master-card">
              <img src={item.mainImage} height={230} width={'100%'} alt={'img' + index} style={{maxWidth: '180px'}}/>
              <div className="card-info">
                <span className="master-description">{item.name + ', ' + item.age}</span>
                <span className="master-description">
                <img className="desc-icon" src={growth} alt={'growth' + index}/>{item.growth}
                  <img className="desc-icon" src={weight} alt={'weight' + index}/>{item.weight}
                  <img className="desc-icon" src={chest} alt={'chest' + index}/>{item.chest}
              </span>
              </div>
            </div>
          ))}
      </div>
      <Link to="/masters" className="nav-text">
        <div className="button-all-masters" style={{maxWidth: widthMobile ? '601px' : '388px', margin: '30px auto'}}>Смотреть всех</div>
      </Link>
    </div>
      ) : (
      <div className="our-masters-page" >
        <h3 className="master-title-main-page">
          НАШИ МАСТЕРА
        </h3>
        <div className="masters-main-page">
          {mastersMockFull.map((item, index) => (
              <div className="master-card">
                <img src={item.mainImage} height={440} width={350} alt={'img' + index} />
                <div className="card-info" >
                  <span className="master-description">{item.name + ', ' + item.age}</span>
                  <span className="master-description">
                <img className="desc-icon" src={growth} alt={'growth' + index}/>{item.growth}
                    <img className="desc-icon" src={weight} alt={'weight' + index}/>{item.weight}
                    <img className="desc-icon" src={chest} alt={'chest' + index}/>{item.chest}
              </span>
                </div>
              </div>
            ))}
        </div>
        <Link to="/masters" className="nav-text">
          <div className="button-all-masters font-montserrat text-14" style={{width: '194px', margin: '30px auto'}}>СМОТРЕТЬ ВСЕХ</div>
        </Link>
      </div>
      )
  );
}