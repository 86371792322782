import React, {useEffect, useState} from 'react';
import Header from "../../components/Header/Header";
import HeaderMobileMenu from "../../components/Header/HeaderMobileMenu";
import './homepage.css';
import logo from '../../utils/images/homePage/logo.png';
import withoutIntim from '../../utils/images/homePage/without-intimacy.svg';
import divider from '../../utils/images/homePage/divider.png';
import OurMasters from "./libs/ourMasters/OurMasters";
import OurPrograms from "./libs/ourPrograms/OurPrograms";
import InteriorHomePage from "./libs/InteriorHomePage/InteriorHomePage";
import OurBenefits from "./libs/ourBenefits/ourBenefits";
import MainStocks from "./libs/mainStocks/MainStocks";
import Footer from "../../components/Footer/Footer";
import MobileBar from "../../components/MobileBar/MobileBar";
import {Button, Input} from "antd";
import InputMask from "react-input-mask";
import MainContacts from "./libs/contacts/MainContacts";
import {Helmet} from "react-helmet";
import currency from "../../utils/images/rub-white.svg";
import clock from "../../utils/images/clock-white.svg";

export default function HomePage() {

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const phoneNumberMask = '+7 999 999 99 99';
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }

  const onNameChange = (event) => {
    setName(event.target.value);
  }

  const onValueChanged = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect( () => {
    window.addEventListener('resize', setWindowDimensions)
  })

  const isMobile = window.innerWidth < 1015;

  return (
    <div>
      <Helmet>
        <title>Салон эротического массаж в Новосибирске | Мужской клуб САФАРИ</title>
        <meta name="description" content="Мужской клуб САФАРИ предлагает релакс для мужчин руками профессиональных мастеров. Большой выбор программ. 100% анонимность. Скидки каждому гостю. Бесплатное такси. Круглосуточная работа. Сексуальные массажистки ждут именно тебя! 18+" />
      </Helmet>
      {isMobile ? (
      <div style={{backgroundColor: 'black', height: '100vh'}}>
        {isOpenMenu ? (
          <HeaderMobileMenu buttonClick={onValueChanged}/>
        ) : (
          <>
            <div className="main-bg-image">
              <a href={`https://wa.me/+79231136666?text=Привет! Хочу записаться на массаж, кто сейчас на смене?`} target="_blank" style={{textDecoration: 'none'}}>
                <div style={{display: 'flex', justifyContent: 'end', position: 'absolute', bottom: '-44px', right: '24px'}}>
                  <div className="button-choose"><span style={{margin: "auto"}}>Приезжай <br/>к нам</span></div>
                </div>
              </a>
              <Header buttonClick={onValueChanged} isOpenMenu={isOpenMenu}/>
              <div className="main-page">
                <img src={logo} alt={'logo'} width={198} height={88}/>
                <p className="main-title">САЛОН ЭРОТИЧЕСКОГО МАССАЖА </p>
              </div>
              <div className="banner-mobile">
                <span><b>КОМПЛЕКСНАЯ ПРОГРАММА</b></span><br></br>
                <span><b>90 МИНУТ + 3 ДОП. УСЛУГИ НА ВЫБОР</b></span>
                <div style={{display: 'flex', alignItems: 'center', marginTop: '4px'}}>
                  <img src={clock} height={13.5} width={13.5} alt="clock"/>
                  <span style={{marginRight: '17px', marginLeft: '3px', fontFamily: 'Montserrat'}}>90 МИН</span>
                  <img src={currency} height={13.5} width={11} alt="rub"/>
                  <span style={{marginLeft: '3px', fontFamily: 'Montserrat'}}>{7000}</span>
                </div>
              </div>
            </div>
            <OurMasters/>
            <OurPrograms/>
            <InteriorHomePage/>
            <OurBenefits/>
            <MainStocks/>
            <Footer/>
            <MobileBar/>
          </>
        )}

      </div>
      ) : (
      <>
        <div className="main-bg-image">
          <Header/>
          <div className="main-page">
            <img src={logo} alt={'logo'} width={361} height={161}/>
            <h1 className="main-title">САЛОН ЭРОТИЧЕСКОГО МАССАЖА </h1>
            <div style={{display: "flex", flexWrap: 'wrap', justifyContent: 'space-between'}}>
              <img src={withoutIntim} alt={'18+'} width={115} height={28}/>
              <div className="main-description">
                <img style={{marginRight: '14px'}} src={divider} alt={'divider'} width={2} height={68}/>
                <span>Хочешь такси за счет заведения?<br/>Хочешь сразу с двумя?</span>
              </div>
            </div>
            <div className="banner-text">
              <span><b>КОМПЛЕКСНАЯ ПРОГРАММА</b></span><br></br>
              <span><b>90 МИНУТ + 3 ДОП. УСЛУГИ НА ВЫБОР</b></span>
              <div>
                <img src={clock} height={20.5} width={20.5} alt="clock"/>
                <span style={{marginRight: '17px', marginLeft: '10px', fontFamily: 'Montserrat'}}>90 МИН</span>
                <img src={currency} height={20} width={17} alt="rub"/>
                <span style={{marginLeft: '10px', fontFamily: 'Montserrat'}}>{7000}</span>
              </div>
            </div>
              <div style={{display: 'flex', justifyContent: 'end', marginTop: '86px'}}>
                <a href={`https://wa.me/+79231136666?text=Привет! Хочу записаться на массаж, кто сейчас на смене?`} target="_blank" style={{textDecoration: 'none'}}>
                <div className="button-choose" ><span style={{margin: "auto"}}>Приезжай <br/>к нам</span></div>
                </a>
              </div>
          </div>
        </div>
        <OurMasters/>
        <OurPrograms/>
        <InteriorHomePage/>
        <OurBenefits/>
        <MainStocks/>
        <div style={{marginTop: '85px', paddingBottom: '50px'}}>
          <img src={logo} alt={'logo'} width={361} height={161}/>
          <p className="waiting-you-title">МЫ ЖДЕМ ВАС</p>
          <p className="waiting-you-description">Оставьте заявку и мы свяжемся с  вами для подбора удобного времени  и программы</p>
          <div>
            <div style={{width: '100%'}}>
              <Input bordered={true} className='input-field' placeholder={'Имя'} onChange={onNameChange} style={{height: 45}}/>
              <div style={{width: '714px', height: '1px', background: 'violet', margin: '0 auto '}}></div>
            </div>
            <div style={{width: '100%', marginBottom: '37px'}}>
              <InputMask
                style={{padding: '2px 10px', width: 'calc(714px - 22px)', height: 45}}
                className='input-field'
                mask={phoneNumberMask}
                maskChar={null}
                onChange={(e)=> setPhone(e.target.value)}
                placeholder={'+7 ___ ___ __ __'}
              />
              <div style={{width: '714px', height: '1px', background: 'violet', margin: '0 auto'}}></div>
            </div>
            <a href={`https://wa.me/+79231136666?text=Здравствуйте%2C+Имя:+${name}+телефон:+${phone}`} target="_blank" style={{textDecoration: 'none'}}>
              <Button
                className="button-main-page-send"
              >
                Отправить
              </Button>
            </a>
          </div>
        </div>
        <MainContacts/>
        <Footer/>
      </>
      )}
    </div>
  );
}