import React, {useEffect, useState} from 'react';
import './stocks.css';
import { stocks } from './mock';
import Header from "../../components/Header/Header";
import HeaderMobileMenu from "../../components/Header/HeaderMobileMenu";
import Footer from "../../components/Footer/Footer";
import {Button, Input, Modal} from "antd";
import parse from 'html-react-parser';
import MobileBar from "../../components/MobileBar/MobileBar";
import InputMask from 'react-input-mask';
import logo from "../../utils/images/homePage/logo.png";
import closeImg from "../../utils/images/header/close.png";
import MainStocks from "../HomePage/libs/mainStocks/MainStocks";
import {Helmet} from "react-helmet";
import popUpBgHorizontal from "../../utils/images/popup1.png";
import popUpMobile from "../../utils/images/popupMobile.png";

export default function Stocks() {

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const phoneNumberMask = '+7 999 999 99 99';

  const onValueChanged = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const modalClose = () => {
    setIsModal(false);
  };

  const modalOpen = () => {
    setIsModal(true);
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  }

  const isMobile = window.innerWidth < 1015;

  const isHorizontal = window.innerHeight < window.innerWidth;

  return (
    <div>
      <Helmet>
        <title>Акции | Мужской клуб САФАРИ</title>
        <meta name="description" content="Скидки каждому гостю. Бесплатное такси. Подари себе релакс от наших профессиональных мастеров со скидкой. Ты точно захочешь вернуться к нам снова!"/>
      </Helmet>
      {
        isMobile ? (
          <div style={{background: 'black'}}>
            {isOpenMenu ? (
              <HeaderMobileMenu buttonClick={onValueChanged}/>
            ) : (
              <>
                <Header buttonClick={onValueChanged} isOpenMenu={isOpenMenu}/>
                <div className="stock-page">
                  <h1 className="stock-title">
                    АКЦИИ
                  </h1>
                  <div>
                    {stocks.map((item, index) => (
                      <div className="stocks-card">
                        <img src={item.image} height={226} width={161} alt={'img' + index}/>
                        <div className="card-text">
                          <h3 className="card-title">{parse(item.title)}</h3>
                          <p className="card-description">{parse(item.description)}</p>
                          <Button className="button-bron font-montserrat text-12" onClick={modalOpen}>ЗАБРОНИРОВАТЬ</Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {isModal && (
                  <Modal
                    maskClosable={true}
                    open={isModal}
                    onCancel={modalClose}
                    footer={null}
                  >
                    <div style={{backgroundImage: `url(${isHorizontal ? popUpBgHorizontal : popUpMobile})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%',  paddingBottom: '40px'}}>
                      <div style={{textAlign: "right"}}>
                        <img src={closeImg} alt={'close'} width={24} height={24} onClick={modalClose}/>
                      </div>
                      <div className="logo-img-pop-up">
                        <img src={logo} alt={'logo'} width={155} height={69}/>
                      </div>
                      <div style={{marginTop: '12px', textAlign: 'center'}}>
                        <h2 className="pop-up-title">Мы ждем тебя!</h2>
                        <p className="pop-up-description" >
                          Оставь контакты и мы свяжемся с тобой<br/>
                          в ближайшее время  для уточнения всех деталей
                        </p>
                        <Input bordered={true} className='input-pop-up' placeholder={'Имя'} onChange={onNameChange}/>
                        <InputMask
                          style={{padding: '2px 10px', width: 'calc(100% - 22px)'}}
                          className='input-pop-up'
                          mask={phoneNumberMask}
                          maskChar={null}
                          onChange={(e)=> setPhone(e.target.value)}
                          placeholder={'+7 ___ ___ __ __'}
                        />
                        <a href={`https://wa.me/+79231136666?text=Здравствуйте%2C+Имя:+${name}+телефон:+${phone}`} target="_blank" style={{textDecoration: 'none'}}>
                          <Button
                            className="pink-gradient-button text-white font-montserrat text-12"
                            style={{width: '100%'}}
                            onClick={modalClose}
                          >
                            Отправить
                          </Button>
                        </a>
                      </div>
                    </div>
                  </Modal>
                )}
                <Footer/>
                <MobileBar/>
              </>
            )}
          </div>
        ) : (
          <>
            <Header/>
            <MainStocks/>
            <Footer/>
          </>
        )
      }
    </div>
  );
}