import React, {useRef} from 'react';
import './ourPrograms.css';
import {Button, Carousel} from "antd";
import { programms, programmsWeb } from "./mock";
import {Link} from 'react-router-dom'
import currency from '../../../../utils/images/programs/currency.svg';
import clock from '../../../../utils/images/programs/clock.svg';

export default function OurPrograms() {
  const bannerRef = useRef();

  const isMobile = window.innerWidth < 1015;

  const widthMobile = window.innerWidth > 599 && window.innerWidth < 1015;

  return (
    isMobile ? (
      <div className="our-programms-page">
        <h3 className="our-programms-title">
          НАШИ ПРОГРАММЫ
        </h3>
        <div className="programms-main-page"  style={{maxWidth: '650px', margin: widthMobile ? '0 auto' : ''}}>
          <Carousel
            infinite={false}
            slidesToShow={widthMobile ? 3 : 2}
            ref={ref => {
              bannerRef.current = ref
            }}
            dots={false}
          >
            <div className="carousel-block">
              <div className="card-block" style={{maxWidth: '180px'}}>
                <img src={programms[0].image} height={120} width={'100%'} alt="programm-one"/>
                <div className="programm-card-title">
                  <span>{programms[0].title}</span>
                </div>
                <div className="program-info">
                  <img className="program-icons" src={clock} height={11} width={11} alt="clock"/>
                  <span style={{marginRight: '17px'}}>{programms[0].time}</span>
                  <img className="program-icons" src={currency} height={10} width={8} alt="rub"/>
                  <span>{programms[0].price}</span>
                </div>
              </div>
              <div className="card-block" style={{maxWidth: '180px'}}>
                <img src={programms[2].image} height={120} width={'100%'} alt="programm-one"/>
                <div className="programm-card-title">
                  <span>{programms[2].title}</span>
                </div>
                <div className="program-info">
                  <img className="program-icons" src={clock} height={11} width={11} alt="clock"/>
                  <span style={{marginRight: '17px'}}>{programms[2].time}</span>
                  <img className="program-icons" src={currency} height={10} width={8} alt="rub"/>
                  <span>{programms[2].price}</span>
                </div>
              </div>
            </div>
            <div className="carousel-block">
              <div className="card-block" style={{maxWidth: '180px'}}>
                <img src={programms[1].image} height={120} width={'100%'} alt="programm-two"/>
                <div className="programm-card-title">
                  <span>{programms[1].title}</span>
                </div>
                <div className="program-info">
                  <img className="program-icons" src={clock} height={11} width={11} alt="clock"/>
                  <span style={{marginRight: '17px'}}>{programms[1].time}</span>
                  <img className="program-icons" src={currency} height={10} width={8} alt="rub"/>
                  <span>{programms[1].price}</span>
                </div>
              </div>
              <div className="card-block" style={{maxWidth: '180px'}}>
                <img src={programms[3].image} height={120} width={'100%'} alt="programm-two"/>
                <div className="programm-card-title">
                  <span>{programms[3].title}</span>
                </div>
                <div className="program-info">
                  <img className="program-icons" src={clock} height={11} width={11} alt="clock"/>
                  <span style={{marginRight: '17px'}}>{programms[3].time}</span>
                  <img className="program-icons" src={currency} height={10} width={8} alt="rub"/>
                  <span>{programms[3].price}</span>
                </div>
              </div>
            </div>
            <div className="carousel-block">
              <div className="card-block" style={{maxWidth: '180px'}}>
                <img src={programms[4].image} height={120} width={'100%'} alt="programm-five"/>
                <div className="programm-card-title">
                  <span>{programms[4].title}</span>
                </div>
                <div className="program-info">
                  <img className="program-icons" src={clock} height={11} width={11} alt="clock"/>
                  <span style={{marginRight: '17px'}}>{programms[4].time}</span>
                  <img className="program-icons" src={currency} height={10} width={8} alt="rub"/>
                  <span>{programms[4].price}</span>
                </div>
              </div>
              <div style={{width: '100%', height: '120px', display: 'flex'}}>
                <Link to={'/programms'}>
                  <div className="button-choose" style={{margin: "auto", textAlign: "center"}}><span style={{margin: "auto"}}>ВЫБРАТЬ ПРОГРАММУ</span></div>
                </Link>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    ) : (
      <div className="our-programms-page">
        <h3 className="our-programms-title">
          ПРОГРАММЫ
        </h3>
        <div className="programms-main-page">
          {programmsWeb.map((item,index) => (
            <div className="card-block" key={index}>
              <img src={item.image} height={303} width={360} alt="programm-one"/>
              <div className="programm-card-title">
                <span>{item.title}</span>
              </div>
              <div className="program-info">
                <img className="program-icons" src={clock} height={11} width={11} alt="clock"/>
                <span style={{marginRight: '17px'}}>{item.time}</span>
                <img className="program-icons" src={currency} height={10} width={8} alt="rub"/>
                <span>{item.price}</span>
              </div>
            </div>
          ))}
        </div>
        <div style={{width: '100%', textAlign: "center", marginTop: '20px'}}>
          <Link to={'/programms'}>
            <Button
              className="pink-gradient-button text-white font-montserrat text-14"
              style={{width: '194px'}}
            >
              СМОТРЕТЬ ВСЕ
            </Button>
          </Link>
        </div>
      </div>
    )
  );
}