import React, {useEffect, useState} from 'react';
import './masters.css';
import { masters } from './mock';
import growth from '../../utils/images/masters/growth.svg';
import weight from '../../utils/images/masters/weight.svg';
import chest from '../../utils/images/masters/chest.svg';
import { Link } from 'react-router-dom';
import Header from "../../components/Header/Header";
import HeaderMobileMenu from "../../components/Header/HeaderMobileMenu";
import Footer from "../../components/Footer/Footer";
import {Button} from "antd";
import MobileBar from "../../components/MobileBar/MobileBar";
import {Helmet} from "react-helmet";

export default function Masters() {

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const onValueChanged = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isMobile = window.innerWidth < 1015;

  const isHorizontal = window.innerWidth > 600 && window.innerWidth > window.innerHeight;

  return (
    <div>
      <Helmet>
        <title>Мастера эротического массажа | Мужской клуб САФАРИ</title>
        <meta name="description" content="Список мастеров эротического массажа мужского клуба САФАРИ. Большой выбор программ. Бесплатное такси. Круглосуточная работа"/>
      </Helmet>
      {
      isMobile ? (
        <div style={{background: 'black'}}>
          {isOpenMenu ? (
            <HeaderMobileMenu buttonClick={onValueChanged}/>
          ) : (
            <>
              <Header buttonClick={onValueChanged} isOpenMenu={isOpenMenu}/>
              <div className="masters-page">
                <h1 className="master-title">
                  МАСТЕРА
                </h1>
                <div className="masters-block-page" style={{maxWidth: isHorizontal ? 601 : 388, margin: '0 auto'}}>
                  {masters.map((item, index) => (
                    <div className="master-card" style={{maxWidth: '180px'}}>
                      <Link to={`/masters/${item.id}`} state={{ master: item }}>
                        <img src={item.mainImage} height={230} width={'100%'} alt={'img' + index} style={{maxWidth: isHorizontal ? 180 : 'unset'}}/>
                      </Link>
                      <div className="card-info" >
                        <span className="master-description">{item.name + ', ' + item.age}</span>
                        <span className="master-description">
                        <img className="desc-icon" src={growth} alt={'growth' + index}/>{item.growth}
                          <img className="desc-icon" src={weight} alt={'weight' + index}/>{item.weight}
                          <img className="desc-icon" src={chest} alt={'chest' + index}/>{item.chest}
                      </span>
                      </div>
                    </div>
                  ))}
                </div>
                <h3 className="master-description-text">
                  Получите фотографии актуальной<br/>
                  смены на WhatsApp
                </h3>
                <h5 className="description-two">
                  Напишите нам и получите фотографии наших мастеров, которые сейчас на смене.<br/>
                  Все фото реальные.
                </h5>
                <a href="https://wa.me/+79231136666" target="_blank" style={{textDecoration: 'none'}}>
                  <Button className="pink-gradient-button text-white font-montserrat text-12">ПОЛУЧИТЬ</Button>
                </a>
              </div>
              <Footer/>
              <MobileBar/>
            </>
          )}
        </div>
      ) : (
        <>
          <Header/>
          <div>
            <div className="masters-page">
              <h1 className="master-title">
                МАСТЕРА
              </h1>
              <div className="masters-block-page">
                {masters.map((item, index) => (
                  <div className="master-card">
                    <Link to={`/masters/${item.id}`} state={{ master: item }}>
                      <img src={item.mainImage} height={470} width={350} alt={'img' + index} />
                    </Link>
                    <div className="card-info">
                      <span className="master-description">{item.name + ', ' + item.age}</span>
                      <span className="master-description">
                      <img className="desc-icon" src={growth} alt={'growth' + index}/>{item.growth}
                        <img className="desc-icon" src={weight} alt={'weight' + index}/>{item.weight}
                        <img className="desc-icon" src={chest} alt={'chest' + index}/>{item.chest}
                    </span>
                    </div>
                  </div>
                ))}
                <div className="master-card"></div>
              </div>
              <h3 className="master-title">
                Получите фотографии актуальной<br/>
                смены на WhatsApp
              </h3>
              <h5 className="description-two">
                Напишите нам и получите фотографии наших мастеров, которые сейчас на смене.<br/>
                Все фото реальные.
              </h5>
              <a href="https://wa.me/+79231136666" target="_blank" style={{textDecoration: 'none'}}>
                <Button className="pink-gradient-button text-white font-montserrat text-14">ПОЛУЧИТЬ</Button>
              </a>
            </div>
          </div>
          <Footer/>
        </>
      )}
    </div>
  );
}