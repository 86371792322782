import React, {useEffect, useState} from 'react';
import './programms.css';
import { programms } from './mock';
import Header from "../../components/Header/Header";
import HeaderMobileMenu from "../../components/Header/HeaderMobileMenu";
import Footer from "../../components/Footer/Footer";
import {Button, Input, Modal} from "antd";
import parse from 'html-react-parser';
import clock from "../../utils/images/programs/clock.svg";
import currency from "../../utils/images/programs/currency.svg";
import girl from "../../utils/images/programs/girl.svg";
import MobileBar from "../../components/MobileBar/MobileBar";
import InputMask from 'react-input-mask';
import logo from "../../utils/images/homePage/logo.png";
import popUpBg from "../../utils/images/popup.png";
import closeImg from "../../utils/images/header/close.png";
import {Helmet} from "react-helmet";
import popUpMobile from "../../utils/images/popupMobile.png";
import popUpHorizontal from "../../utils/images/popup1.png";

export default function Programms() {

  const [isModal, setIsModal] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [activeProgramm, setActiveProgramm] = useState();

  const phoneNumberMask = '+7 999 999 99 99';


  const modalClose = () => {
    setIsModal(false);
  };

  const modalOpen = () => {
    setIsModal(true);
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  }

  const onValueChanged = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const onLearnMoreClick = (programm) => {
    setActiveProgramm(programm);
  };

  const onCloseProgramm = () => {
    setActiveProgramm('');
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isMobile = window.innerWidth < 1015;


  const isHorizontal = window.innerHeight < window.innerWidth;

  return (
    <div>
      <Helmet>
        <title>Программы эротического массажа | Мужской клуб САФАРИ</title>
        <meta name="description" content="Список программ эротического массажа мужского клуба САФАРИ. Профессиональные мастера подарят тебе невероятное блаженство. 100% анонимность. Круглосуточная работа. "/>
      </Helmet>
      {
        isMobile ? (
          <div style={{background: 'black'}}>
            {isOpenMenu ? (
              <HeaderMobileMenu buttonClick={onValueChanged}/>
            ) : (
              <>
                <Header buttonClick={onValueChanged} isOpenMenu={isOpenMenu}/>
                <div className="stock-page">
                  <h1 className="stock-title">
                    ПРОГРАММЫ
                  </h1>
                  <div>
                    {programms.map((item, index) => (
                      <>
                        <div className="programms-card">
                          <img src={activeProgramm?.imageColored === item.imageColored ? item.imageColored : item.image} height={'78%'} width={'100%'} alt={'img' + index}/>
                          <div className="programm-title">
                            <h3 className="card-title">{parse(item.title)}</h3>
                          </div>
                          {item.secondPrice && item.secondTime && (
                            <div className="programm-card-info-add">
                              <img className="program-icons" src={clock} height={11} width={11} alt="clock"/>
                              <span style={{marginRight: '17px'}}>{item.secondTime}</span>
                              <img className="program-icons" src={currency} height={10} width={8} alt="rub"/>
                              <span>{item.secondPrice}</span>
                            </div>
                          )}
                          {item.price && item.time && (
                            <div className="programm-card-info">
                              <img className="program-icons" src={clock} height={11} width={11} alt="clock"/>
                              <span style={{marginRight: '17px'}}>{item.time}</span>
                              <img className="program-icons" src={currency} height={10} width={8} alt="rub"/>
                              <span>{item.price}</span>
                            </div>
                          )}
                        </div>
                        {item.title === activeProgramm?.title && (
                          (index === programms.length - 2) ?
                            <div className="card-description-block">
                              {item.descriptionList.map((desc, index2) => (
                                <>
                                  <p className="programm-includes">{desc.title}</p>
                                  <ul className="description-list">
                                    {parse(desc.description)}
                                  </ul>
                                  <div className="classic-info">
                                    <div style={{display: 'flex', alignItems: 'center', marginRight: '8px', paddingTop: '16px'}}>
                                      <img className="program-icons" src={clock} height={11} width={11} alt="clock"/>
                                      <span>{desc.time}</span>
                                    </div>
                                    <div style={{paddingTop: '2px', marginRight: '8px', width: '1px', height: '29px', background: 'linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%)'}}/>
                                    <div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
                                      <img className="program-icons" src={girl} height={29} width={9} alt="girl" style={{marginRight: '10px'}}/>
                                      <img className="program-icons" src={currency} height={10} width={8} alt="rub"  style={{paddingTop: '18px'}}/>
                                      <span style={{paddingTop: '18px'}}>{desc.price1}</span>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
                                      <img className="program-icons" src={girl} height={29} width={9} alt="girl"/>
                                      <img className="program-icons" src={girl} height={29} width={9} alt="girl" style={{marginRight: '8px'}}/>
                                      <img className="program-icons" src={currency} height={10} width={8} alt="rub" style={{paddingTop: '18px'}}/>
                                      <span style={{paddingTop: '18px'}}>{desc.price2}</span>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                      <img className="program-icons" src={girl} height={29} width={9} alt="girl"/>
                                      <img className="program-icons" src={girl} height={29} width={9} alt="girl"/>
                                      <img className="program-icons" src={girl} height={29} width={9} alt="girl" style={{marginRight: '10px'}}/>
                                      <img className="program-icons" src={currency} height={10} width={8} alt="rub" style={{paddingTop: '18px'}}/>
                                      <span style={{paddingTop: '18px'}}>{desc.price3}</span>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                            :
                            <div className="card-description-block">
                              <span className="programm-includes">Программа включает:</span>
                              <ul className="description-list" style={{whiteSpace: index === programms.length-1 ? 'pre-line' : 'unset'}}>
                                {parse(item.description)}
                              </ul>
                            </div>
                        )}
                        <div className="card-buttons">
                          {item.title === activeProgramm?.title ?
                            <div className="button-learn-more font-montserrat text-12" onClick={onCloseProgramm}>СКРЫТЬ</div>
                            :
                            <div className="button-learn-more font-montserrat text-12" onClick={() => onLearnMoreClick(item)}>ПОДРОБНЕЕ</div>
                          }
                          <Button className="pink-gradient-button text-white font-montserrat text-12" style={{height: '37px'}} onClick={modalOpen}>ЗАПИСАТЬСЯ</Button>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
                {isModal && (
                  <Modal
                    maskClosable={true}
                    open={isModal}
                    onCancel={modalClose}
                    footer={null}
                  >
                    <div style={{backgroundImage: `url(${isHorizontal ? popUpHorizontal : popUpMobile})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', paddingBottom: '40px'}}>
                      <div style={{textAlign: "right"}}>
                        <img src={closeImg} alt={'close'} width={24} height={24} onClick={modalClose}/>
                      </div>
                      <div className="logo-img-pop-up">
                        <img src={logo} alt={'logo'} width={155} height={69}/>
                      </div>
                      <div style={{marginTop: '12px', textAlign: 'center'}}>
                        <h2 className="pop-up-title">Мы ждем тебя!</h2>
                        <p className="pop-up-description" >
                          Оставь контакты и мы свяжемся с тобой<br/>
                          в ближайшее время  для уточнения всех деталей
                        </p>
                        <Input bordered={true} className='input-pop-up' placeholder={'Имя'} onChange={onNameChange}/>
                        <InputMask
                          style={{padding: '2px 10px', width: 'calc(100% - 22px)'}}
                          className='input-pop-up'
                          mask={phoneNumberMask}
                          maskChar={null}
                          onChange={(e)=> setPhone(e.target.value)}
                          placeholder={'+7 ___ ___ __ __'}
                        />
                        <a href={`https://wa.me/+79231136666?text=Здравствуйте%2C+Имя:+${name}+телефон:+${phone}`} target="_blank" style={{textDecoration: 'none'}}>
                          <Button
                            className="pink-gradient-button text-white font-montserrat text-12"
                            style={{width: '100%'}}
                            onClick={modalClose}
                          >
                            Отправить
                          </Button>
                        </a>
                      </div>
                    </div>
                  </Modal>
                )}
                <Footer/>
                <MobileBar/>
              </>
            )}
          </div>
        ) : (
          <>
            <Header/>
            <div className="stock-page">
              <h1 className="stock-title">
                ПРОГРАММЫ
              </h1>
              <div style = {{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '1500px', margin: '0 auto'}}>
                {programms.map((item, index) => (
                  <div style={{marginRight: '40px'}}>
                    <div className="programms-card" >
                      <img src={activeProgramm?.imageColored === item.imageColored ? item.imageColored : item.image} height={275} width={360} alt={'img' + index}/>
                      <div className="programm-title">
                        <h3 className="card-title">{parse(item.title)}</h3>
                      </div>
                      {item.secondPrice && item.secondTime && (
                        <div className="programm-card-info-add">
                          <img className="program-icons" src={clock} height={11} width={11} alt="clock"/>
                          <span style={{marginRight: '17px'}}>{item.secondTime}</span>
                          <img className="program-icons" src={currency} height={10} width={8} alt="rub"/>
                          <span>{item.secondPrice}</span>
                        </div>
                      )}
                      {item.price && item.time && (
                        <div className="programm-card-info">
                          <img className="program-icons" src={clock} height={11} width={11} alt="clock"/>
                          <span style={{marginRight: '17px'}}>{item.time}</span>
                          <img className="program-icons" src={currency} height={10} width={8} alt="rub"/>
                          <span>{item.price}</span>
                        </div>
                      )}
                    </div>
                    {item.title === activeProgramm?.title && (
                      (index === programms.length - 2) ?
                        <div className="card-description-block">
                          {item.descriptionList.map((desc, index2) => (
                            <>
                              <p className="programm-includes">{desc.title}</p>
                              <ul className="description-list" >
                                {parse(desc.description)}
                              </ul>
                              <div className="classic-info">
                                <div style={{display: 'flex', alignItems: 'center', marginRight: '8px', paddingTop: '16px'}}>
                                  <img className="program-icons" src={clock} height={11} width={11} alt="clock"/>
                                  <span>{desc.time}</span>
                                </div>
                                <div style={{paddingTop: '2px', marginRight: '8px', width: '1px', height: '29px', background: 'linear-gradient(131.6deg, #B469FF 26.49%, #DD3A99 100%)'}}/>
                                <div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
                                  <img className="program-icons" src={girl} height={29} width={9} alt="girl" style={{marginRight: '10px'}}/>
                                  <img className="program-icons" src={currency} height={10} width={8} alt="rub"  style={{paddingTop: '18px'}}/>
                                  <span style={{paddingTop: '18px'}}>{desc.price1}</span>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
                                  <img className="program-icons" src={girl} height={29} width={9} alt="girl"/>
                                  <img className="program-icons" src={girl} height={29} width={9} alt="girl" style={{marginRight: '8px'}}/>
                                  <img className="program-icons" src={currency} height={10} width={8} alt="rub" style={{paddingTop: '18px'}}/>
                                  <span style={{paddingTop: '18px'}}>{desc.price2}</span>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                  <img className="program-icons" src={girl} height={29} width={9} alt="girl"/>
                                  <img className="program-icons" src={girl} height={29} width={9} alt="girl"/>
                                  <img className="program-icons" src={girl} height={29} width={9} alt="girl" style={{marginRight: '10px'}}/>
                                  <img className="program-icons" src={currency} height={10} width={8} alt="rub" style={{paddingTop: '18px'}}/>
                                  <span style={{paddingTop: '18px'}}>{desc.price3}</span>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                        :
                        <div className="card-description-block">
                          <span className="programm-includes">Программа включает:</span>
                          <ul className="description-list" style={{whiteSpace: index === programms.length-1 ? 'pre-line' : 'unset'}}>
                            {parse(item.description)}
                          </ul>
                        </div>
                    )}
                    <div className="card-buttons" style={{width: 356}}>
                      {item.title === activeProgramm?.title ?
                        <div className="button-learn-more font-montserrat text-12" onClick={onCloseProgramm} style={{width: 169}}>СКРЫТЬ</div>
                        :
                        <div className="button-learn-more font-montserrat text-12" onClick={() => onLearnMoreClick(item)} style={{width: 169}}>ПОДРОБНЕЕ</div>
                      }
                      <Button className="pink-gradient-button text-white font-montserrat text-12" style={{height: '37px', width: 169}} onClick={modalOpen}>ЗАПИСАТЬСЯ</Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {isModal && (
              <Modal
                maskClosable={true}
                open={isModal}
                onCancel={modalClose}
                footer={null}
                width={'720px'}
              >
                <div style={{backgroundImage: `url(${popUpBg})`, backgroundSize: '100% 100%', backgroundPosition: 'bottom right', padding: '24px 24px 40px'}}>
                  <div style={{textAlign: "right", marginTop: 10}}>
                    <img src={closeImg} alt={'close'} width={24} height={24} onClick={modalClose}/>
                  </div>
                  <div style={{width: 340, marginLeft: 20}}>
                    <div className="logo-img-pop-up">
                      <img src={logo} alt={'logo'} width={155} height={69}/>
                    </div>
                    <div style={{marginTop: '12px', textAlign: 'center'}}>
                      <h2 className="pop-up-title">Мы ждем тебя!</h2>
                      <p className="pop-up-description" >
                        Оставь контакты и мы свяжемся с тобой<br/>
                        в ближайшее время  для уточнения всех деталей
                      </p>
                      <Input bordered={true} className='input-pop-up' placeholder={'Имя'} onChange={onNameChange}/>
                      <InputMask
                        style={{padding: '2px 10px', width: 'calc(100% - 22px)'}}
                        className='input-pop-up'
                        mask={phoneNumberMask}
                        maskChar={null}
                        onChange={(e)=> setPhone(e.target.value)}
                        placeholder={'+7 ___ ___ __ __'}
                      />
                      <a href={`https://wa.me/+79231136666?text=Здравствуйте%2C+Имя:+${name}+телефон:+${phone}`} target="_blank" style={{textDecoration: 'none'}}>
                        <Button
                          className="pink-gradient-button text-white font-montserrat text-12"
                          style={{width: '100%'}}
                          onClick={modalClose}
                        >
                          Отправить
                        </Button>
                      </a>
                    </div>
                  </div>
                  <div>
                  </div>
                </div>
              </Modal>
            )}
            <Footer/>
          </>
        )
      }
    </div>
  );
}