import React, {FC, useEffect} from 'react';
import burgerMenu from '../../utils/images/header/menu.png';
import close from '../../utils/images/header/close.png';
import phone from '../../utils/images/header/phone.svg';
import tgIcon from '../../utils/images/tg-icon.png';
import './header.css';
import {Row, Button} from 'antd';
import {Link} from "react-router-dom";

export const Header: FC = ({buttonClick, isOpenMenu}) => {

  // const activeTab = window.location.pathname;
  const isMobile = window.innerWidth < 1015;

  return (
    isMobile ? (
    <Row className="header" justify="space-between" align="middle" style={{background: isOpenMenu && '#F8F8F8'}}>
        <>
          <div style={{zIndex: 2}}>
            {!isOpenMenu && <img src={burgerMenu} alt="burger-menu" onClick={buttonClick} width={36} height={33}/>}
          </div>
          <div style={{zIndex: 2}}>
            {isOpenMenu ? (
              <img src={close} alt="close-header" onClick={buttonClick} width={24} height={24}/>
            ) : (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <a href="https://t.me/erotik54">
                  <img src={tgIcon} style={{marginRight: "15px"}} width={32} height={32}/>
                </a>
                <a href="tel:+79231136666">
                  <Button className="header-button text-white font-montserrat">
                    <img src={phone} style={{marginRight: "10px"}}/>Позвонить нам
                  </Button>
                </a>
              </div>
            )}
          </div>
        </>
    </Row>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px',
              width: '78vw',
              margin: '0 auto',
            }}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'left'}}>
              <div style={{ marginRight: '40px'}}>
                <p>Новосибирск, ул. Галущука 4</p>
                <a href={'tel:+79231136666'} style={{textDecoration: 'none', color: 'white'}}>
                  <span>+7 923 113 66 66</span>
                </a>
              </div>
              <span style={{marginRight: '40px'}}>Круглосуточно</span>
            </div>
            <div style={{display: 'flex'}}>
              <Link to={'/'} style={{textDecoration: 'none'}}>
                <p className="nav-header-text">Главная</p>
              </Link>
              <Link to={'/masters'} style={{textDecoration: 'none'}}>
                <p className="nav-header-text">Мастера</p>
              </Link>
              <Link to={'/programms'} style={{textDecoration: 'none'}}>
                <p className="nav-header-text">Программы</p>
              </Link>
              <Link to={'/stocks'} style={{textDecoration: 'none'}} >
                <p className="nav-header-text">Акции</p>
              </Link>
              <Link to={'/interior'} style={{textDecoration: 'none'}} >
                <p className="nav-header-text">Интерьер</p>
              </Link>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <a href="https://t.me/erotik54">
                <img src={tgIcon} style={{marginRight: "15px"}} width={30} height={30}/>
              </a>
              <a href={`https://wa.me/+79231136666`} target="_blank" style={{textDecoration: 'none'}}>
                <Button
                  className="button-main-page-call"
                >
                  Заказать звонок
                </Button>
              </a>
            </div>
          </div>
          <div style={{backgroundColor: 'rgb(60, 60, 60)', height: '1px', width: '100vw'}}></div>
        </>
    )
  );
}

export default Header;