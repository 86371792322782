import React, {FC} from 'react';
import withoutIntimacy from '../../utils/images/homePage/without-intimacy.svg';
import logo from '../../utils/images/homePage/logo.png';
import './footer.css';
import location from "../../utils/images/contacts/location.svg";
import phone from "../../utils/images/contacts/phone.svg";
import {Button} from "antd";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import {Link} from 'react-router-dom';

export const Footer: FC = ({isTextCenter, isFullHeight}) => {

  const isMobile = window.innerWidth < 1015;
  const mapData = {
    center: [55.052572, 82.904332],
    zoom: 16,
  };

  return (
    isMobile ? (
      <>
        <div className="footer" style={{height: isFullHeight && 'calc(100vh - 124px)'}}>
          <>
            <div className={`contacts-main-page ${isTextCenter ? 'text-center' : ''}`}>
              <h1 className="contacts-title">
                КОНТАКТЫ
              </h1>
              <div>
                <div className={`contacts-block ${isTextCenter ? 'text-center' : ''}`}>
                  <img src={location} alt={'location'} width={20} height={20} style={{ marginRight: '16px'}}/>
                  <div className="contacts-text">
                    <span>Новосибирск, ул. Галущука, 4</span>
                  </div>
                </div>
                <div className={`contacts-block ${isTextCenter ? 'text-center' : ''}`}>
                  <img src={phone} alt={'phone'} width={20} height={20} style={{ marginRight: '16px'}}/>
                  <div className="contacts-text">
                    <span style={{fontSize: '14px'}} >+7 (923) 113-66-66</span>
                  </div>
                </div>
              </div>
              <div className={`contacts-block ${isTextCenter ? 'text-center' : ''}`}>
                <a href="https://wa.me/+79231136666" target="_blank" style={{textDecoration: 'none'}}>
                  <Button className="whats-app-button text-white font-montserrat">WHATS APP</Button>
                </a>
                <a href="tel:+79231136666" style={{textDecoration: 'none'}}>
                  <div className="button-contact-us">ПОЗВОНИТЬ</div>
                </a>
              </div>
            </div>
            <div style={{width: '100%', height: '225px'}}>
              <YMaps>
                <Map
                  defaultState={mapData}
                  width="100%"
                  height="100%"
                >
                  <Placemark
                    geometry={[55.052572, 82.904332]}
                    options={{
                      iconColor: 'blue',
                    }}/>
                </Map>
              </YMaps>
            </div>
          </>
          <div style={{margin: '0 auto', bottom: isFullHeight ? '24px' : 'unset', width: isFullHeight ? 'calc(100% - 48px)' : '100%'}}>
            <div style={{marginTop: '40px'}}>
              <img src={logo} alt="footer-logo" width={145} height={65}/>
            </div>
            <div>
              <img src={withoutIntimacy} alt="without-intimacy" width={84} height={20.5}/>
            </div>
            <p>Салон не оказывает услуг интимного характера!<br/>
              Ограничение по возрасту 18+<br/>
              Есть противопоказания, перед сеансом проконсультируйтесь с врачом<br/>
              ООО "Президент"</p>
          </div>
        </div>
      </>
    ) : (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            width: '78vw',
            margin: '0 auto',
          }}>
          <div>
            <Link to={'/'}>
            <img src={logo} alt={'logo'} width={160} height={71} />
            </Link>
          </div>
          <div style={{display: 'flex'}}>
            <Link to={'/masters'} style={{textDecoration: 'none'}}>
              <p className="nav-footer-text">Мастера</p>
            </Link>
            <Link to={'/programms'} style={{textDecoration: 'none'}}>
              <p className="nav-footer-text">Программы</p>
            </Link>
            <Link to={'/stocks'} style={{textDecoration: 'none'}} >
              <p className="nav-footer-text">Акции</p>
            </Link>
            <Link to={'/interior'} style={{textDecoration: 'none'}} >
              <p className="nav-footer-text">Интерьер</p>
            </Link>
          </div>
          <div>
            <img src={withoutIntimacy} alt={'logo'} width={116} height={28.3} style={{paddingTop: '10px'}}/>
          </div>
        </div>
        <div style={{backgroundColor: 'rgb(60, 60, 60)', height: '1px', width: '100vw'}}></div>
        <p className="footer-text">
          Салон не оказывает услуг интимного характера!<br/>
          Ограничение по возрасту 18+<br/>
          Есть противопоказания, перед сеансом проконсультируйтесь с врачом<br/>
          ООО "Президент"
        </p>
      </>
    )
  );
}

export default Footer;