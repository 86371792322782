import React, {useEffect, useRef, useState} from 'react';
import './interior.css';
import Header from "../../components/Header/Header";
import HeaderMobileMenu from "../../components/Header/HeaderMobileMenu";
import Footer from "../../components/Footer/Footer";
import {Image, Carousel} from "antd";
import one from '../../utils/images/interior/1.png';
import two from '../../utils/images/interior/2.png';
import three from '../../utils/images/interior/3.png';
import four from '../../utils/images/interior/4.png';
import five from '../../utils/images/interior/5.png';
import oneMobile from '../../utils/images/interior/1-mobile.png';
import twoMobile from '../../utils/images/interior/2-mobile.png';
import threeMobile from '../../utils/images/interior/3-mobile.png';
import fourMobile from '../../utils/images/interior/4-mobile.png';
import fiveMobile from '../../utils/images/interior/5-mobile.png';
import leftArrow from '../../utils/images/interior/left-arrow.svg';
import rightArrow from '../../utils/images/interior/right-arrow.svg';
import MobileBar from "../../components/MobileBar/MobileBar";
import webOne from "../../utils/images/interior/main-web1.png";
import webTwo from "../../utils/images/interior/main-web2.png";
import webThree from "../../utils/images/interior/main-web3.png";
import webFour from "../../utils/images/interior/web4.png";
import webFive from "../../utils/images/interior/web5.png";
import {Helmet} from "react-helmet";

export default function Interior() {

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [visible, setVisible] = useState(false);
  const intRef = useRef();
  const bottomRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const slideList = [
    {main: one, preview: oneMobile},
    {main: two, preview: twoMobile},
    {main: three, preview: threeMobile},
    {main: four, preview: fourMobile},
    {main: five, preview: fiveMobile}
  ];
  const sliderBottomList = [
    {main: two, preview: twoMobile},
    {main: three, preview: threeMobile},
    {main: four, preview: fourMobile},
    {main: five, preview: fiveMobile},
    {main: one, preview: oneMobile}
  ];

  const onLeftArrowClick = () => {
    intRef.current.prev();
    bottomRef.current.prev();
  }

  const onRightArrowClick = () => {
    intRef.current.next();
    bottomRef.current.next();
  }

  const onSwipe = (current) => {
    console.log(current);
    if (current === 'right') {
      intRef.current.prev();
      bottomRef.current.prev();
    } else {
      intRef.current.next();
      bottomRef.current.next();
    }
  }

  const onValueChanged = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  const isMobile = window.innerWidth < 1015;

  return (
    <div>
      <Helmet>
        <title>Интерьер | Мужской клуб САФАРИ</title>
        <meta name="description" content="Раскошный интерьер, прекрасная музыка и компания сексуальных, обворожительных девушек. Все это тебя ждет в мужском клубе САФАРИ. Работаем круглосуточно."/>
      </Helmet>
      {isMobile ? (
      <div style={{background: 'black'}}>
        {isOpenMenu ? (
          <HeaderMobileMenu buttonClick={onValueChanged}/>
        ) : (
          <>
            <Header buttonClick={onValueChanged} isOpenMenu={isOpenMenu}/>
            <div className="interior-page">
              <h1 className="interior-title">
                ИНТЕРЬЕР
              </h1>
              {window.innerWidth < 376 ?
                <p className="interior-description" style={{marginBottom: '20px'}}>
                  Раскошный интерьер, прекрасная музыка и компания сексуальных,<br/> обворожительных девушек...<br/>
                  Разве не о таком отдыхе мечтает каждый мужчина?
                </p>
                :
                <p className="interior-description" style={{marginBottom: '20px'}}>
                  Раскошный интерьер, прекрасная музыка и компания сексуальных, обворожительных девушек...<br/>
                  Разве не о таком отдыхе мечтает каждый мужчина?
                </p>
              }
              <div className="interior-block">
                <div style={{ display: 'none' }}>
                  <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>
                    {slideList.map((item, index) => (
                      <Image
                        style={{margin: "0 auto", width: '100%'}}
                        src={item.main}
                        alt={`slide + ${index}`}
                        height={window.innerWidth < 1015 ? 228 : 800}
                        preview={{src: item.preview}}
                      />
                    ))}
                  </Image.PreviewGroup>
                </div>
                <Carousel
                  ref={ref => {
                    intRef.current = ref
                  }}
                  dots={false}
                  swipeToSlide={false}
                  draggable={false}
                  onSwipe={onSwipe}
                >
                  {slideList.map((item, index) => (
                    <div key={index} style={{width: ''}}>
                      <Image
                        style={{margin: "0 auto", width: '100%'}}
                        src={item.main}
                        alt={`slide + ${index}`}
                        height={window.innerWidth < 1015 ? 228 : 800}
                        preview={{ visible: false }}
                        onClick={() => setVisible(true)}
                      />
                    </div>
                  ))}
                </Carousel>
                <div className="left-arrow" onClick={onLeftArrowClick}>
                  <img className="left-arrow-icon" src={leftArrow} alt="left"/>
                </div>
                <div className="right-arrow" onClick={onRightArrowClick}>
                  <img className="right-arrow-icon" src={rightArrow} alt="right"/>
                </div>
                <Carousel
                  style={{marginTop: '12px'}}
                  ref={ref => {
                    bottomRef.current = ref
                  }}
                  dots={false}
                  slidesToShow={4}
                  onSwipe={onSwipe}
                >
                  {sliderBottomList.map((item, index) => (
                    <div key={index} style={{padding: '0 7px'}}>
                      <Image
                        style={{ width: 'calc(100% - 5px)'}}
                        src={item.main}
                        alt={`slide + ${index}`}
                        height={window.innerWidth < 1015 ? 84 : 800}
                        preview={{ visible: false }}
                        onClick={() => setVisible(true)}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            <Footer/>
            <MobileBar/>
          </>
        )}
      </div>
      ) : (
      <>
        <Header/>
        <div className="interior-main-page">
          <p className="interior-main-title">
            ИНТЕРЬЕР
          </p>
          <p className="interior-desc">
            Раскошный интерьер, прекрасная музыка и компания сексуальных, <br/>
            обворожительных девушек... <br/>
            Разве не о таком отдыхе мечтает каждый мужчина?
          </p>
          <div className="interior-block">
            <Image.PreviewGroup>
              <div style={{marginRight: '60px'}}>
                <Image className="inter-img" src={webOne} preview={{src: one}} width={560} height={563}/>
              </div>
              <div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '60px', width: '560px'}}>
                  <Image className="inter-img" src={webTwo} preview={{src: two}} width={259} height={260}/>
                  <Image className="inter-img" src={webThree} preview={{src: five}} width={265} height={260}/>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '38px'}}>
                  <Image className="inter-img" src={webFour} preview={{src: three}} width={259} height={260}/>
                  <Image className="inter-img" src={webFive} preview={{src: four}} width={265} height={260}/>
                </div>
              </div>
            </Image.PreviewGroup>
          </div>
        </div>
        <Footer/>
      </>
      )}
    </div>
  );
}