import React, {FC} from 'react';
import close from '../../utils/images/header/close.png';
import safariText from '../../utils/images/header/safari-text.png';
import './header.css';
import {Button} from 'antd';
import {Link} from "react-router-dom";
import phone from "../../utils/images/header/phone.svg";

export const HeaderMobileMenu: FC = ({buttonClick, isOpenMenu}) => {

  return (
    <div className="header-mobile-menu">
      <img src={close} alt="close-header" onClick={buttonClick} width={24} height={24} style={{position: 'absolute', top: '24px', right: '24px'}}/>
      <Link to='/' className="nav-text">
        <img src={safariText} alt='safari' width={159} height={56.3} style={{marginTop: '48px'}}/>
      </Link>
      <div className="header-menu-block">
        <Link to='/masters' className="nav-text">
          <h2 className="nav-title">Мастера</h2>
        </Link>
        <Link to='/programms' className="nav-text">
        <h2 className="nav-title">Программы</h2>
        </Link>
        <Link to='/interior' className="nav-text">
        <h2 className="nav-title">Интерьер</h2>
        </Link>
        <Link to='/stocks' className="nav-text">
        <h2 className="nav-title">Акции</h2>
        </Link>
        <Link to='/contacts' className="nav-text">
        <h2 className="nav-title">Контакты</h2>
        </Link>
        <a href="tel:+79231136666" style={{textDecoration: 'none'}}>
        <Button className="header-button text-white font-montserrat text-12" style={{margin: '69px auto 0'}}>
          <img src={phone} style={{marginRight: "10px"}} alt="phone"/>Позвонить нам
        </Button>
        </a>
        <a href="https://wa.me/+79231136666" target="_blank" style={{textDecoration: 'none'}}>
          <Button className="whats-app-btn text-white font-montserrat text-12">Whats app</Button>
        </a>
      </div>
    </div>
  );
}

export default HeaderMobileMenu;