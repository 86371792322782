import classic from '../../../../utils/images/programs/classic-program.png';
import tanec from '../../../../utils/images/programs/dikiy-tanec.png';
import mensClub from '../../../../utils/images/programs/mens-club.png';
import amazon from '../../../../utils/images/programs/pokori-amazonku.png';
import pup from '../../../../utils/images/programs/prishel-uvidel-podaril.png';
import ritual from '../../../../utils/images/programs/ritual.png';
import vlast from '../../../../utils/images/programs/vlast.png';
import vodopad from '../../../../utils/images/programs/vodopad.png';

export const programms  = [
  {
    image: amazon,
    title: 'Покори амазонку',
    price: '20000',
    time: '120 мин',
  },
  {
    image: pup,
    title: 'Пришел. увидел, подарил',
    price: '10000',
    time: '90 мин',
  },
  {
    image: classic,
    title: 'Классические программы',
    price: 'от 4000',
    time: 'от 60 мин',
  },
  {
    image: tanec,
    title: 'Дикий танец',
    price: '6000',
    time: '60 мин',
  },
  {
    image: mensClub,
    title: "Men's club Safari",
    price: '25000',
    time: '90 мин',
  }
];


export const programmsWeb  = [
  {
    image: ritual,
    title: 'Таинственный\n' + 'ритуал',
    price: '7000',
    time: '90 мин',
  },
  {
    image: vlast,
    title: 'Власть незнакомки',
    price: '17000',
    time: '90 мин',
  },
  {
    image: vodopad,
    title: 'Водопад желаний',
    price: '14000',
    time: '180 мин',
  },
];
