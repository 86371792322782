import React, {useEffect, useState} from 'react';
import Header from "../../components/Header/Header";
import HeaderMobileMenu from "../../components/Header/HeaderMobileMenu";
import Footer from "../../components/Footer/Footer";
import MobileBar from "../../components/MobileBar/MobileBar";
import MainContacts from "../HomePage/libs/contacts/MainContacts";
import location from "../../utils/images/contacts/location.svg";
import phone from "../../utils/images/contacts/phone.svg";
import {Button} from "antd";
import {Map, Placemark, YMaps} from "react-yandex-maps";

export default function Contacts() {

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const onValueChanged = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const mapData = {
    center: [55.052572, 82.904332],
    zoom: 16,
  };

  const isMobile = window.innerWidth < 1015;

  return (
    <div style={{background: 'black'}}>
      {isMobile ? (
        <>
          {isOpenMenu ? (
            <HeaderMobileMenu buttonClick={onValueChanged}/>
          ) : (
            <div style={{minHeight: '800px'}}>
              <Header buttonClick={onValueChanged} isOpenMenu={isOpenMenu}/>
              <Footer isFullHeight isTextCenter/>
              <MobileBar/>
            </div>
          )}
        </>
      ) : (
        <>
          <Header/>
          <div style={{backgroundColor: '#140101'}}>
            <h1 className="main-contacts-title">
              КОНТАКТЫ
            </h1>
            <div style={{display: 'flex', justifyContent: 'space-between',  width: '78vw', margin: '0 auto 38px'}}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '37px'}}>
                  <img src={location} alt={'location'} width={38} height={38} style={{ marginRight: '16px'}}/>
                  <div className="main-contacts-text">
                    <span>Новосибирск, ул. Галущука, 4</span>
                  </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <img src={phone} alt={'phone'} width={38} height={38} style={{ marginRight: '16px'}}/>
                  <div className="main-contacts-text">
                    <span >+7 (923) 113-66-66</span>
                  </div>
                </div>
              </div>
              <a href={`https://wa.me/+79231136666?`} target="_blank" style={{textDecoration: 'none'}}>
                <Button
                  className="pink-gradient-button text-white font-montserrat text-12"
                  style={{width: '155px'}}
                >
                  WHATS APP
                </Button>
              </a>
            </div>
            <div style={{ width: '78vw', margin: '0 auto', paddingBottom: '87px'}}>
              <div style={{width: '100%', height: 'calc(78vw * 0.43)'}}>
                <YMaps>
                  <Map
                    defaultState={mapData}
                    width="100%"
                    height="100%"
                  >
                    <Placemark
                      geometry={[55.052572, 82.904332]}
                      options={{
                        iconColor: 'blue',
                      }}/>
                  </Map>
                </YMaps>
              </div>
            </div>
          </div>
          <Footer/>
        </>
      )}
    </div>
  );
}