import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import HomePage from "./pages/HomePage/HomePage";
import './utils/fonts/style.css';
import Masters from "./pages/Masters/Masters";
import MasterId from "./pages/Masters/MasterId/MasterId";
import Stocks from "./pages/Stocks/Stocks";
import Interior from "./pages/Interior/Interior";
import Contacts from "./pages/Contacts/Contacts";
import Programms from "./pages/Programms/Programms";
import { Button, Modal } from 'antd';
import React, {useEffect, useState} from 'react';
import logo from './utils/images/homePage/logo.png';
import adult from './utils/images/adult.svg';
import isAdultMobile from './utils/images/isAdultPopMobile.png';
import isAdultWeb from './utils/images/isAdultPopWeb.png';

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalClose = () => {
    setIsModalOpen(false);
    localStorage.setItem('isAdult', true);
  };

  useEffect(() => {
    localStorage.getItem('isAdult') ? setIsModalOpen(false) : setIsModalOpen(true);
  }, [])

  const isMobile = window.innerWidth < 1015;

  const isHorizontal = window.innerWidth > window.innerHeight;


  return (
    <div className="App">
      {isModalOpen ? (
        <div style={{minHeight: '450px', width: '100vw', height: '100vh', backgroundImage: `url(${isHorizontal ? isAdultWeb : isAdultMobile})`, backgroundRepeat: 'no-repeat', backgroundSize: '100vw 100vh'}}>
          <div className="logo-img">
            <img src={logo} alt={'logo'} width={isMobile ? '300px' : '450px'} height={isMobile ? '150px' : '200px'}/>
          </div>
          <div style={{marginTop: '12px', textAlign: 'center'}}>
            <img src={adult} alt={'18+'} width={44} height={20}/>
            <p className="adult-description text-white" style={{marginTop: '40px', marginBottom: '20px'}}>
              Контент на сайте предназначен <br/>
              <b className={'adult-bold'}>строго для совершеннолетних</b>
            </p>
            <div className="button-contact-us" style={{margin: '0 auto'}} onClick={modalClose}>Мне уже есть 18 лет</div>
          </div>
        </div>
      ) : (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/programms" element={<Programms/>}/>
          <Route path="/masters" element={<Masters/>}/>
          <Route path="/masters/:id" element={<MasterId/>}/>
          <Route path="/interior" element={<Interior/>}/>
          <Route path="/stocks" element={<Stocks/>}/>
          <Route path="/contacts" element={<Contacts/>}/>
        </Routes>
      </BrowserRouter>
      )}
    </div>
  );
}

export default App;
