import alice1 from '../../utils/images/masters/alice/alice1.png';
import alice2 from '../../utils/images/masters/alice/alice2-full.png';
import alice3Full from '../../utils/images/masters/alice/alice3-full.jpeg';
import alice4Full from '../../utils/images/masters/alice/alice4-full.jpeg';
import alice3 from '../../utils/images/masters/alice/alice3.png';
import alice4 from '../../utils/images/masters/alice/alice4.png';
import aliceWb from '../../utils/images/masters/alice/alice-wb.jpg';
import alice1Web from '../../utils/images/masters/alice/alice1-web.png';
import alice2Web from '../../utils/images/masters/alice/alice2-web.png';
import alice3Web from '../../utils/images/masters/alice/alice3-web.png';
import alice4Web from '../../utils/images/masters/alice/alice4-web.png';
import eva1 from '../../utils/images/masters/eva/eva1.png';
import eva2 from '../../utils/images/masters/eva/eva2.png';
import eva3 from '../../utils/images/masters/eva/eva3.png';
import eva4 from '../../utils/images/masters/eva/eva4.png';
import eva2Full from '../../utils/images/masters/eva/eva2-full.jpeg';
import eva3Full from '../../utils/images/masters/eva/eva3-full.jpeg';
import eva4Full from '../../utils/images/masters/eva/eva4-full.jpeg';
import evaWb from '../../utils/images/masters/eva/eva-wb.jpg';
import eva1Web from '../../utils/images/masters/eva/eva1-web.png';
import eva2Web from '../../utils/images/masters/eva/eva2-web.png';
import eva3Web from '../../utils/images/masters/eva/eva3-web.png';
import eva4Web from '../../utils/images/masters/eva/eva4-web.png';
import lina1 from '../../utils/images/masters/lina/lina1.png';
import lina2 from '../../utils/images/masters/lina/lina2.png';
import lina3 from '../../utils/images/masters/lina/lina3.png';
import lina2Full from '../../utils/images/masters/lina/lina2-full.jfif';
import lina3Full from '../../utils/images/masters/lina/lina3-full.jfif';
import linaWb from '../../utils/images/masters/lina/lina-wb.jpg';
import lina1Web from '../../utils/images/masters/lina/lina1-web.png';
import lina2Web from '../../utils/images/masters/lina/lina2-web.png';
import lina3Web from '../../utils/images/masters/lina/lina3-web.png';
import mira1 from '../../utils/images/masters/mira/mira1.png';
import mira2 from '../../utils/images/masters/mira/mira2.png';
import mira3 from '../../utils/images/masters/mira/mira3.png';
import mira4 from '../../utils/images/masters/mira/mira4.png';
import mira2Full from '../../utils/images/masters/mira/mira2-full.jpeg';
import mira3Full from '../../utils/images/masters/mira/mira3-full.jpeg';
import mira4Full from '../../utils/images/masters/mira/mira4-full.jpeg';
import miraWb from '../../utils/images/masters/mira/mira-wb.jpg';
import mira1Web from '../../utils/images/masters/mira/mira1-web.png';
import mira2Web from '../../utils/images/masters/mira/mira2-web.png';
import mira3Web from '../../utils/images/masters/mira/mira3-web.png';
import mira4Web from '../../utils/images/masters/mira/mira4-web.png';
import miya1 from '../../utils/images/masters/mia/mia1.png';
import miya2 from '../../utils/images/masters/mia/mia2.png';
import miya3 from '../../utils/images/masters/mia/mia3.png';
import miya4 from '../../utils/images/masters/mia/mia4.png';
import miya2Full from '../../utils/images/masters/mia/mia2-full.jpeg';
import miya3Full from '../../utils/images/masters/mia/mia3-full.jpeg';
import miya4Full from '../../utils/images/masters/mia/mia4-full.jpeg';
import miyaWb from '../../utils/images/masters/mia/mia-wb.jpg';
import miya1Web from '../../utils/images/masters/mia/mia1-web.png';
import miya2Web from '../../utils/images/masters/mia/mia2-web.png';
import miya3Web from '../../utils/images/masters/mia/mia3-web.png';
import miya4Web from '../../utils/images/masters/mia/mia4-web.png';
import sonya1 from '../../utils/images/masters/sonya/sonya1.jpg';
import sonya2 from '../../utils/images/masters/sonya/sonya2.png';
import sonya4 from '../../utils/images/masters/sonya/sonya4.png';
import sonya1Web from '../../utils/images/masters/sonya/sonya1-web.png';
import sonya2Web from '../../utils/images/masters/sonya/sonya2-web.png';
import sonya3Web from '../../utils/images/masters/sonya/sonya3-web.png';
import sonya4Web from '../../utils/images/masters/sonya/sonya4-web.png';
import sonya2Full from '../../utils/images/masters/sonya/sonya2-full.jpeg';
import sonya3Full from '../../utils/images/masters/sonya/sonya3-full.jpeg';
import sonya4Full from '../../utils/images/masters/sonya/sonya4-full.jpeg';
import sonyaWb from '../../utils/images/masters/sonya/sonya-wb.jpg';
import zarina1 from '../../utils/images/masters/zarina/zarina1.png';
import zarina2 from '../../utils/images/masters/zarina/zarina2.png';
import zarina3 from '../../utils/images/masters/zarina/zarina3.png';
import zarina4 from '../../utils/images/masters/zarina/zarina4.png';
import zarina2Full from '../../utils/images/masters/zarina/zarina2-full.jpeg';
import zarina3Full from '../../utils/images/masters/zarina/zarina3-full.jpeg';
import zarina4Full from '../../utils/images/masters/zarina/zarina4-full.jpeg';
import zarinaWb from '../../utils/images/masters/zarina/zarina-wb.jpg';
import zarina1Web from '../../utils/images/masters/zarina/zarina1-web.png';
import zarina2Web from '../../utils/images/masters/zarina/zarina2-web.png';
import zarina3Web from '../../utils/images/masters/zarina/zarina3-web.png';
import zarina4Web from '../../utils/images/masters/zarina/zarina4-web.png';
import nastya1 from '../../utils/images/masters/nastya/nastya1.png';
import nastya2 from '../../utils/images/masters/nastya/nastya2.png';
import nastya2Full from '../../utils/images/masters/nastya/nastya2-full.jpeg';
import nastyaWb from '../../utils/images/masters/nastya/nastya-wb.jpg';
import nastya1Web from '../../utils/images/masters/nastya/nastya1-web.png';
import nastya2Web from '../../utils/images/masters/nastya/nastya2-web.png';

export const masters  = [
  {
    id: 1,
    image: [sonya3Full, sonya4, sonya1, sonya2],
    fullImage: [sonya3Full, sonya4Full, sonya1, sonya2Full],
    webImage: [sonya1Web, sonya2Web, sonya3Web, sonya4Web],
    name: 'Соня',
    age: '24',
    growth: '167',
    weight: '57',
    chest: '2',
    hair: 'Русая',
    mainImage: sonyaWb,
  },
  {
    id: 2,
    image: [alice1, alice2, alice3, alice4],
    fullImage: [alice1, alice2Web, alice3Full, alice4Full],
    webImage: [alice1Web, alice2Web, alice3Web, alice4Web],
    name: 'Алиса',
    age: '20',
    growth: '180',
    weight: '56',
    chest: '2',
    hair: 'Брюнетка',
    mainImage: aliceWb,
  },
  {
    id: 3,
    image: [zarina1, zarina2, zarina3, zarina4],
    fullImage: [zarina1, zarina2Full, zarina3Full, zarina4Full],
    webImage: [zarina1Web, zarina2Web, zarina3Web, zarina4Web],
    name: 'Зарина',
    age: '24',
    growth: '167',
    weight: '52',
    chest: '3',
    hair: 'Брюнетка',
    mainImage: zarinaWb,
  },
  {
    id: 4,
    image: [mira1, mira2, mira3, mira4],
    fullImage: [mira1, mira2Full, mira3Full, mira4Full],
    webImage: [mira1Web, mira3Web, mira2Web, mira4Web],
    name: 'Мира',
    age: '19',
    growth: '163',
    weight: '58',
    chest: '3',
    hair: 'Рыжая',
    mainImage: miraWb,
  },
  {
    id: 5,
    image: [nastya1, nastya2],
    fullImage: [nastya1, nastya2Full],
    webImage: [nastya1Web, nastya2Web],
    name: 'Настя',
    age: '20',
    growth: '175',
    weight: '55',
    chest: '1,5',
    hair: 'Русая',
    mainImage: nastyaWb,
  },
  {
    id: 6,
    image: [miya1, miya2, miya3, miya4],
    fullImage: [miya1, miya2Full, miya3Full, miya4Full],
    webImage: [miya1Web, miya2Web, miya3Web, miya4Web],
    name: 'Мия',
    age: '23',
    growth: '168',
    weight: '54',
    chest: '2,5',
    hair: 'Брюнетка',
    mainImage: miyaWb,
  },
  {
    id: 7,
    image: [eva1, eva2, eva3, eva4],
    fullImage: [eva1, eva2Full, eva3Full, eva4Full],
    webImage: [eva1Web, eva2Web, eva3Web, eva4Web],
    name: 'Ева',
    age: '24',
    growth: '165',
    weight: '63',
    chest: '4',
    hair: 'Блондинка',
    mainImage: evaWb,
  },
  {
    id: 8,
    image: [lina1, lina2, lina3],
    fullImage: [lina1, lina2Full, lina3Full],
    webImage: [lina1Web, lina2Web, lina3Web],
    name: 'Лина',
    age: '19',
    growth: '169',
    weight: '60',
    chest: '2',
    hair: 'Брюнетка',
    mainImage: linaWb,
  }
];

